'use client'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { ErrorFallback, errorHandler } from '@/config/ErrorBoundary'
import store from '@/store/configStore'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactNode, useState } from 'react'
import {
	QueryClient as QueryClientNew,
	QueryClientProvider as QueryClientProviderNew,
} from '@tanstack/react-query'

const errorService = (error: Error, info: { componentStack: string }) => {
	console.error(error)
	if (errorHandler) errorHandler.report(JSON.stringify(info))
}

export function BeforeRouter({ children }: { children: ReactNode }) {
	const [queryClientOld] = useState(() => new QueryClient())
	const [queryClientNew] = useState(() => new QueryClientNew())

	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={(error, errorInfo) => errorService(error, errorInfo)}
			onReset={() => {
				console.log('Reintentar')
			}}
		>
			<Provider store={store}>
				<QueryClientProvider client={queryClientOld}>
					<QueryClientProviderNew client={queryClientNew}>
						{children}
					</QueryClientProviderNew>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</Provider>
		</ErrorBoundary>
	)
}
